
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import MemberList from "@/components/List/MemberList.vue";
import { Tabs } from "ant-design-vue";
import { namespace } from "vuex-class";

const UserModule = namespace("user");

@Component({
  name: "CooperationModal",
  components: {
    MemberList,
    [Tabs.name]: Tabs,
    [Tabs.TabPane.name]: Tabs.TabPane,
  },
  filters: {
    //局部过滤器
  },
})
export default class CooperationModal extends Vue {
  @UserModule.State("companyId") companyId!: string;

  public coopFilePrivilege: string = "edit";
  public url: string = "";
  public memberList: any[] = [];
  public selectKey: Array<string | number> = [];
  public isAdd: boolean = false;
  public searchParams: string = "";
  public tabIndex: string = "1";
  public laoding: boolean = false;
  public searchList: any[] = [];
  public selectSearchKey: Array<string | number> = [];
  public recentList: any[] = [];
  public selectRecentKey: Array<string | number> = [];
  public cooperate: any = {};
  public dropdown: any = [
    {
      title: "查看者",
      value: "view",
    },
    {
      title: "编辑者",
      value: "edit",
    },
  ];

  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop({ default: "public" })
  readonly type!: string;
  @Prop({ default: false })
  readonly visible!: boolean;
  @Prop({ default: false })
  readonly loading!: boolean;
  @Prop()
  readonly file!: any;
  @Prop()
  readonly coop!: any;
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  @Ref("searchInput")
  searchInputRef!: any;

  // 关闭
  handleCancel(): void {
    this.$emit("close");
  }
  // 文件协作初始化
  coopFileInfoInit() {
    if (this.file) {
      console.log(this.file);
      const params = {
        userFileId: this.file.id,
        coopFileDescription: this.file.userFileDescription,
        coopFileName: this.file.userFileName,
        coopFileSize: this.file.userFileSize,
        coopFileType: this.file.userFileType,
        coopType: this.type,
        extensionName: this.file.extensionName,
        fileId: this.file.fileId,
        remark: this.file.remark,
        companyId: 'person',
      };
      this.$apis.coopFileInfoAddFile(params).then((res: any) => {
        if (res.code === "200") {
          this.cooperate = res.data;
          this.setUrl();
          this.queryMemberList();
          this.$emit('coopFileAddEnd')
        }
      });
    } else {
      this.cooperate = this.coop;
      this.setUrl();
      this.queryMemberList();
    }
  }
  // 设置邀请链接
  setUrl() {
    if (this.type === "public" || this.type === "member") {
      const url =
        this.coopFilePrivilege === "view"
          ? this.cooperate.readUrl
          : this.cooperate.editUrl;
      this.url = location.origin + "/cooperationInvited?key=" + url;
    }
  }
  // 复制链接
  handleCopy() {
    console.log(this.file, this.coop);
    let name = this.file
      ? `${this.file.userFileName}.${this.file.extensionName}`
      : `${this.coop.coopFileName}.${this.coop.extensionName}`;
    var inp = document.createElement("input");
    inp.value = `【星云文档】${name}
    ${this.url}`;
    inp.style.opacity = "0";
    document.body.appendChild(inp);
    inp.select();
    document.execCommand("Copy");
    this.$message.success("复制成功");
    inp.remove();
  }
  // 获取成员列表
  queryMemberList() {
    this.$apis
      .coopFileInfoQueryUsersByCoopId({
        coopId: this.cooperate.id,
      })
      .then((res: any) => {
        if (res.code === "200") {
          const list = res.data.users.map((i: any) => {
            return {
              ...i,
              role: i.coopFilePrivilege,
              disabled: i.coopFilePrivilege === "super",
            };
          });
          // 按权限将成员进行排序
          const superList: any[] = [];
          const editList: any[] = [];
          const viewList: any[] = [];
          list.forEach((item: any) => {
            if (item.role === "super") {
              superList.push(item);
            } else if (item.role === "edit") {
              editList.push(item);
            } else {
              viewList.push(item);
            }
          });
          this.memberList = [...superList, ...editList, ...viewList];
        }
      });
  }
  // 标签页切换
  tabChange(e: any) {
    console.log(e);
  }
  // 协作类型变更
  coopFilePrivilegeChange() {
    this.setUrl();
  }
  // 搜索表单输入
  handleChange(): void {
    if (this.searchParams !== "") {
      this.isAdd = true;
      this.$nextTick(() => {
        this.searchInputRef.focus();
      });
    }
  }
  // 搜索
  handleSearch() {
    if (this.searchParams === "") return;
    if (!this.isAdd) {
      this.isAdd = true;
      this.$nextTick(() => {
        this.searchInputRef.focus();
      });
    }
    this.tabIndex = "1";
    this.laoding = true;
    this.$apis
      .coopUserRelSearch({
        coopId: this.cooperate.id,
        isPage: 0,
        name: this.searchParams,
      })
      .then((res: any) => {
        if (res.code === "200") {
          this.selectSearchKey = [];
          this.searchList = res.data.list;
        }
      })
      .finally(() => {
        this.laoding = false;
      });
  }
  // 选择成员
  memberSelection(params: any, type: string) {
    let checker = params[0].target.checked;
    let value = params[1];
    if (type === "member") {
      if (checker) {
        this.selectKey.push(value.userId);
      } else {
        let index = this.selectKey.indexOf(value.userId);
        if (index !== -1) this.selectKey.splice(index, 1);
      }
    } else if (type === "search") {
      if (checker) {
        this.selectSearchKey.push(value.userId);
      } else {
        let index = this.selectSearchKey.indexOf(value.userId);
        if (index !== -1) this.selectSearchKey.splice(index, 1);
      }
    } else if (type === "recent") {
      if (checker) {
        this.selectRecentKey.push(value.userId);
      } else {
        let index = this.selectRecentKey.indexOf(value.userId);
        if (index !== -1) this.selectRecentKey.splice(index, 1);
      }
    }
  }
  // 全选/取消全选
  memberSelectionAll(params: any, type: string) {
    let checker = params[0].target.checked;
    if (type === "member") {
      if (checker) {
        this.selectKey = [];
        this.memberList.forEach((i: any) => {
          if (i.role !== "super") {
            this.selectKey.push(i.userId);
          }
        });
      } else {
        this.selectKey = [];
      }
    } else if (type === "search") {
      if (checker) {
        this.selectSearchKey = [];
        this.searchList.forEach((i: any) => {
          if (i.role !== "super") {
            this.selectSearchKey.push(i.userId);
          }
        });
      } else {
        this.selectSearchKey = [];
      }
    } else if (type === "recent") {
      if (checker) {
        this.selectRecentKey = [];
        this.recentList.forEach((i: any) => {
          if (i.role !== "super") {
            this.selectRecentKey.push(i.userId);
          }
        });
      } else {
        this.selectRecentKey = [];
      }
    }
  }
  // 批量处理权限
  selectionRole(params: any, type: string) {
    let role = params[0];
    if (type === "member") {
      const that = this;
      if (role === "delete") {
        this.$confirm({
          title: "删除成员",
          content: `已选中${this.selectKey.length}位成员,是否确认删除选中成员？`,
          okText: "是",
          okType: "danger",
          cancelText: "否",
          onOk() {
            that.$apis
              .coopUserBatchDelete({
                coopId: that.cooperate.id,
                userIds: that.selectKey,
              })
              .then((res: any) => {
                if (res.code === "200") {
                  that.$message.success("删除成功");
                  that.selectKey = [];
                  that.queryMemberList();
                }
              });
            console.log("OK");
          },
          onCancel() {
            console.log("Cancel");
          },
        });
      } else {
        this.$confirm({
          title: "更改权限",
          content: `已选中${this.selectKey.length}位成员,是否确认修改其权限？`,
          okText: "是",
          cancelText: "否",
          onOk() {
            that.$apis
              .coopUserBatchUpdate({
                coopId: that.cooperate.id,
                coopFilePrivilege: role,
                userIds: that.selectKey,
              })
              .then((res: any) => {
                if (res.code === "200") {
                  that.$message.success("更改成功");
                  that.selectKey = [];
                  that.queryMemberList();
                }
              });
            console.log("OK");
          },
          onCancel() {
            console.log("Cancel");
          },
        });
      }
    } else if (type === "search") {
      if (role === "delete") {
        for (let i = this.searchList.length - 1; i >= 0; i--) {
          for (let j = this.selectSearchKey.length - 1; j >= 0; j--) {
            if (this.searchList[i].userId === this.selectSearchKey[j]) {
              this.searchList.splice(i, 1);
              this.selectSearchKey.splice(j, 1);
              break;
            }
          }
        }
      } else {
        this.searchList.forEach((i) => {
          this.selectSearchKey.forEach((j) => {
            if (i.userId === j) {
              this.$set(i, "role", role);
            }
          });
        });
      }
    } else if (type === "recent") {
      if (role === "delete") {
        for (let i = this.recentList.length - 1; i >= 0; i--) {
          for (let j = this.selectRecentKey.length - 1; j >= 0; j--) {
            if (this.recentList[i].userId === this.selectRecentKey[j]) {
              this.recentList.splice(i, 1);
              this.selectRecentKey.splice(j, 1);
              break;
            }
          }
        }
      } else {
        this.recentList.forEach((i) => {
          this.selectRecentKey.forEach((j) => {
            if (i.userId === j) {
              this.$set(i, "role", role);
            }
          });
        });
      }
    }
  }
  // 处理权限
  handlePrivileges(value: any, type: string) {
    // 是否是添加成员列表
    if (this.isAdd) {
      if (this.tabIndex === "1") {
        let index = this.searchList.findIndex((i) => i.userId === value.userId);
        this.$set(this.searchList[index], "role", type);
      } else {
        let index = this.recentList.findIndex((i) => i.userId === value.userId);
        this.$set(this.recentList[index], "role", type);
      }
    } else {
      if (type === "tranPower") {
        this.$apis
          .coopUserRelTranPower({
            id: this.coop.id,
            userId: value.userId,
          })
          .then((res: any) => {
            if (res.code == 200) {
              this.$message.success("转让成功");
              (this.$parent as any).queryList();
              this.handleCancel();
            }
          });
      } else if (value.coopFilePrivilege !== type) {
        // 直接更改成员权限
        const params = {
          coopId: this.cooperate.id,
          coopFilePrivilege: type,
          userId: value.userId,
        };
        this.$apis.coopUserRelUpdate(params).then((res: any) => {
          if (res.code === "200") {
            this.$message.success("更改成功");
            this.queryMemberList();
          }
        });
      }
    }
  }
  // 删除成员
  handleDelete(value: any) {
    if (this.isAdd) {
      if (this.tabIndex === "1") {
        let index = this.searchList.findIndex((i) => i.userId === value.userId);
        this.searchList.splice(index, 1);
        let selectionIndex = this.selectSearchKey.indexOf(value.userId);
        if (selectionIndex !== -1)
          this.selectSearchKey.splice(selectionIndex, 1);
      } else {
        let index = this.recentList.findIndex((i) => i.userId === value.userId);
        this.recentList.splice(index, 1);
        let selectRecentIndex = this.selectRecentKey.indexOf(value.userId);
        if (selectRecentIndex !== -1)
          this.selectRecentKey.splice(selectRecentIndex, 1);
      }
    } else {
      // 直接删除成员
      this.$apis
        .coopUserRelBatchDelete({
          coopId: this.cooperate.id,
          userId: value.userId,
        })
        .then((res: any) => {
          if (res.code === "200") {
            this.$message.success("删除成功");
            this.queryMemberList();
          }
        });
    }
  }
  // 确认添加成员
  handleAddOk() {
    const memberList =
      this.tabIndex === "1" ? this.searchList : this.recentList;
    const addList = memberList.reduce((pre, cur) => {
      if (cur.role) {
        pre.push({ userId: cur.userId, coopFilePrivilege: cur.role });
      }
      return pre;
    }, []);
    if (addList.length > 0) {
      this.$apis
        .coopUserRelAdd({
          coopId: this.cooperate.id,
          userList: addList,
        })
        .then((res: any) => {
          if (res.code === "200") {
            this.$message.success("添加成功");
            this.isAdd = false;
            this.queryMemberList();
          }
        });
    } else {
      this.$message.warning("请至少给一名成员添加权限");
    }
  }

  //创建前钩子函数
  created(): void {
    if (this.$route.path.includes("cooperation")) {
      this.dropdown = [
        {
          title: "查看者",
          value: "view",
        },
        {
          title: "编辑者",
          value: "edit",
        },
        {
          title: "转让权限",
          value: "tranPower",
        },
      ];
    }
    this.isAdd = false;
    this.coopFileInfoInit();
  }
}
