
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import MydocMenuItem from "./menuItem.vue";
@Component({
  components: { MydocMenuItem },
})
export default class ClassComponent extends Vue {
  @Prop(Array) list!: any[];
  @Prop({ type: Array, default: () => [] }) checkedList!: any[];



  onClickItem(el: any) {
    this.$emit("handleClick", el);
  }

  mounted(){
    console.log('this.list',this.list);
    console.log('this.checkedList',this.checkedList);
  }

}




