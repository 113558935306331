<template>
  <div>
    <a-icon v-if="item.icon" :type="item.icon" :style="item.iconStyle" class="icon"/>
    <h-icon
      v-else-if="item.hicon"
      :type="item.hicon"
      :style="item.iconStyle"
      class="icon"
    ></h-icon>
    <svg
      v-else-if="item.iconfont"
      class="icon"
      :style="item.iconStyle"
      aria-hidden="true"
    >
      <use :xlink:href="`#icon-${item.iconfont}`"></use>
    </svg>
    <span :style="item.fontStyle">{{ item.text }}</span>
  </div>
</template>

<script>
export default {
  name: "mydocMenuItem",
  props: {
    item: Object
  }
}
</script>

<style scoped>
.icon {
  margin-right: 5px;
}
</style>