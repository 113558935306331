
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
@Component({
  name: "MemberList",
  components: {},
  filters: {
    //局部过滤器
  },
})
export default class MemberList extends Vue {
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop({ default: false })
  readonly selection!: boolean;
  @Prop({ default: "" })
  readonly selectionText!: string;
  @Prop()
  readonly selectKey!: Array<string | number>;
  @Prop({ required: true })
  readonly dataSource!: any[];

  @Prop({ default: false })
  readonly deleteMemberFlag!:boolean




  @Prop({ default: "已加入的成员" })
  readonly title!: string;
  @Prop({
    default: () => {
      return [
        {
          title: "查看者",
          value: "view",
        },
        {
          title: "编辑者",
          value: "edit",
        },
      ];
    },
  })
  readonly dropdown!: any;
  //计算属性
  get selectionAll(): boolean {
    return this.selectKey.length === this.length && this.selectKey.length !== 0;
  }

  get length(): number {
    if (this.dataSource.findIndex((i) => i.role === "super") !== -1) {
      return this.dataSource.length - 1;
    } else {
      return this.dataSource.length;
    }
  }
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')

  getTitle(value: string) {
    if (value === "super") return "超级管理员";
    let result = this.dropdown.find((i: any) => i.value === value);
    return result ? result.title : "添加权限";
  }

  // 多选旋转
  handleSelection(e: any, value: any) {


    this.$emit("selection", e, value);
  }

  // 全选/取消全选
  handleSelectionAll(e: any) {
    this.$emit("selectionAll", e);
  }

  // 批量处理权限
  handleRoleAll(role: string) {
    this.$emit("selectionRole", role);
  }

  handleClick(value: any, type: string) {
    this.$emit("privileges", value, type);
  }

  handleDelete(value: any) {

    // this.$emit("delete", value);
    this.showDeleteConfirm(value)
  }
  


  // 删除成员的二次确认弹框
  showDeleteConfirm(value: any) {
    const _that=this
      this.$confirm({
        centered:true,
        title: '确认删除该成员吗?',
        content: '',
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          // console.log('OK');
          _that.$emit("delete", value);
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }

  // 点击列表
  handleListItem(value: any) {
    console.log('选择的成员---------------', value);
    this.$emit("selectRow", value);
  }

  //@Ref()
  //创建前钩子函数
  created(): void {


    console.log("🚀 初始成员:", this.dataSource)

  }
}
